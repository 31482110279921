import React, { useState } from "react";
import styled, { css } from "styled-components";
import Container from "../../components/Container";
import SEO from "../../components/SEO";
import PageHeader from "../../components/PageHeader";
import LinkButton from "../../components/LinkButton";
import Row from "../../components/Row";
import Column from "../../components/Column";
import Card from "../../components/Card";
import CareersBanner from "../../components/CareersBanner";
import { min_lg } from "../../utils/mediaQuery";

const contactTypeOptions = [
  "採用に関するお問い合わせ",
  "当社への営業",
  "その他のお問い合わせ",
];

export default () => {
  const [contactType, setContactType] = useState(contactTypeOptions[0]);

  return (
    <>
      <SEO
        description="株式会社SocialDogへのお問い合わせ。"
        title="お問い合わせ"
      />
      <PageHeader title="Contact" lead="お問い合わせ" />
      <Container>
        <ContactInfoCardBase>
          <Card>
            <ContactInfoTitle>
              SocialDogサービスに関するお問い合わせ
            </ContactInfoTitle>
            <ContactInfoParagraph>
              SocialDogサービスに関するお問い合わせは、このページからではなく、下記の「SocialDog
              お問い合わせフォーム」のページからご連絡ください。
            </ContactInfoParagraph>
            <ContactInfoLinkButtonBase>
              <LinkButton
                as="a"
                href="https://web.social-dog.net/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                SocialDogお問い合わせフォーム
              </LinkButton>
            </ContactInfoLinkButtonBase>
          </Card>
        </ContactInfoCardBase>
      </Container>
      <Container>
        <ContactInfoTitle>その他のお問い合わせ</ContactInfoTitle>
        <form
          name="contact"
          method="post"
          action="/contact/success"
          netlify-honeypot="連絡先"
          data-netlify="true"
        >
          {/* You still need to add the hidden input with the form name to your JSX form */}
          {/* @see https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
          <input type="hidden" name="form-name" value="contact" />
          {/* 「連絡先」はボット送信防止のハニーポット */}
          <input type="hidden" name="連絡先" />
          <input
            type="hidden"
            name="subject"
            value={`${contactType} (お問い合わせフォーム)`}
          />
          <FormGroupRowBase>
            <Row gutter="10px">
              <Column col={3}>
                <Label htmlFor="contactCompany">会社名</Label>
              </Column>
              <Column>
                <Input type="text" name="会社名" id="contactCompany" />
              </Column>
            </Row>
          </FormGroupRowBase>
          <FormGroupRowBase>
            <Row gutter="10px">
              <Column col={3}>
                <Label htmlFor="contactName">お名前</Label>
              </Column>
              <Column>
                <Input type="text" name="お名前" id="contactName" required />
              </Column>
            </Row>
          </FormGroupRowBase>
          <FormGroupRowBase>
            <Row gutter="10px">
              <Column col={3}>
                <Label htmlFor="contactEmail">メールアドレス</Label>
              </Column>
              <Column>
                <Input
                  type="email"
                  name="メールアドレス"
                  id="contactEmail"
                  required
                />
              </Column>
            </Row>
          </FormGroupRowBase>
          <FormGroupRowBase>
            <Row gutter="10px">
              <Column col={3}>
                <Label htmlFor="contactType">お問い合わせ種別</Label>
              </Column>
              <Column>
                <SelectBase>
                  <Select
                    name="お問い合わせ種別"
                    id="contactType"
                    required
                    value={contactType}
                    onChange={e => setContactType(e.target.value)}
                  >
                    {contactTypeOptions.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </Select>
                </SelectBase>
              </Column>
            </Row>
          </FormGroupRowBase>
          <FormGroupRowBase>
            <Row gutter="10px">
              <Column col={3}>
                <Label htmlFor="contactBody">お問い合わせ内容</Label>
              </Column>
              <Column>
                <Textarea
                  name="お問い合わせ内容"
                  id="contactBody"
                  rows="10"
                  required
                ></Textarea>
              </Column>
            </Row>
          </FormGroupRowBase>
          <Row justifyContent="flex-end" gutter="10px">
            <Column col={9}>
              <LinkButton narrow as="button" type="submit">
                送信
              </LinkButton>
            </Column>
          </Row>
        </form>
      </Container>
      <CareersBanner />
    </>
  );
};

const ContactInfoCardBase = styled.div`
  padding: 0 0 72px;
`;

const ContactInfoTitle = styled.h2`
  color: ${({ theme }) => theme.colors.main};
  font-size: 16px;
  margin: 0 0 8px;
`;

const ContactInfoParagraph = styled.p`
  margin: 0 0 16px;
`;

const ContactInfoLinkButtonBase = styled.div`
  text-align: center;
`;

const FormGroupRowBase = styled.div`
  padding: 0 0 24px;
`;

const Label = styled.label`
  display: block;
  text-align: left;
  padding: 14px 0;
  font-size: 14px;

  ${min_lg(css`
    text-align: right;
  `)}
`;

const formControlStyle = css`
  appearance: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.inputBackground};
  width: 100%;
  padding: 10px 16px;
  transition: 0.1s linear background-color, 0.1s linear box-shadow;
  /* iOS では、font-size が 16px 未満だと、テキストボックスをフォーカスした際に
  ズームしてしまうので注意 */
  font-size: 16px;

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.focus};
    outline: 0;
    background: ${({ theme }) => theme.colors.baseSub};
  }
`;

const Input = styled.input`
  ${formControlStyle}
`;

const SelectBase = styled.div`
  position: relative;

  &:after {
    content: "";
    display: block;
    border: 4px solid ${({ theme }) => theme.colors.muted};
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-25%);
  }
`;

const Select = styled.select`
  ${formControlStyle}

  &::-ms-expand {
    display: none;
  }
`;

const Textarea = styled.textarea`
  ${formControlStyle}
`;
